import { StaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { IconContext } from "react-icons";
import { GiGavel, GiPhone, GiBookshelf } from 'react-icons/gi';

import './Rows.css'

const homeRows = props => (
  <StaticQuery
    query={query}
    render={data => (
      <IconContext.Provider value={{ color: "var(--primary-color)", className: "icon" }}>
      <div className="body-wrapper">
        <div className="row">
          <Img alt="Portrait of Jim Feleen" className="row-image portrait" fluid={data.imageOne.childImageSharp.fluid}/>
          <div className="row-content">
            <h3>Jim Feleen</h3>
            <GiGavel />
            <p className="row-text">
            Jim Feleen is a general practice attorney who offers services in real estate law, title law, personal injury and probate law in Claremont, NH and throughout Sullivan County. Explore the links below to learn more about Jim, his practice, and how to contact his office.
            </p>
            <div className="link-wrapper"><Link to="/about/">MORE ABOUT JIM</Link></div>
          </div>
        </div>
        <div className="row">
          <Img alt="Gavel resting on the table" className="row-image" fluid={data.imageTwo.childImageSharp.fluid}/>
          <div className="row-content">
            <h3>Practice Areas</h3>
            <GiBookshelf/>
            <p className="row-text">
            The Law Office of James G. Feleen is a general practice law firm with specialized emphasis in several areas including Real Estate Law, Personal Injury, Probate Administration, and Estate Planning. Jim serves his clients out of his office on Pleasant Street in Claremont, New Hampshire. Click the link below to learn more about his practice.
            </p>
            <div className="link-wrapper"><Link to="/practice-areas/">MORE ABOUT OUR PRACTICE</Link></div>
          </div>
        </div>
        <div className="row">
          <Img alt="Yellow phone next to blue sofa" className="row-image" fluid={data.imageThree.childImageSharp.fluid}/>
          <div className="row-content">
            <h3>Get in Touch</h3>
            <GiPhone />
            <p className="row-text">
            Jim serves his clients out of his office in Claremont, New Hampshire. If you would like to get in touch with Jim, have questions about the services he offers or would like to schedule an appointment, please click the link below for more details. Or call (603) 504-6507.
            </p>
            <div className="link-wrapper"><Link to="/contact/">HOW TO CONTACT JIM</Link></div>
          </div>
        </div>
      </div>
      </IconContext.Provider>
    )}
  />
);

const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "jim-feleen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "gavel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "phone-next-to-blue-sofa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default homeRows;
