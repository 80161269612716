import React from 'react'
import SEO from '../components/seo/seo'
import HeroLarge from '../components/hero/heroLarge'
import HomeRows from '../components/rows/homeRows'
import Disclaimer from '../components/disclaimer/disclaimer'

const IndexPage = () => (
  <>
    <SEO 
      title="Home" 
      description="Claremont NH Attorney James G. Feleen is a general practice lawyer, providing real estate, personal injury, and estate planning. 603-504-6507"
      keywords={['law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'real estate', 'probate administration', 'probate', 'personal injury', 'injury', 'wills', 'estate planning', 'title company', 'general practice', 'accident', 'insurance claim', 'negligence', 'medical malpractice', 'work injuries']} />
    <HeroLarge />
    <HomeRows />
    <Disclaimer />
  </>
)

export default IndexPage
