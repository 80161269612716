import { StaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

import './heroLarge.css'

const heroLarge = props => (
  <StaticQuery
    query={query}
    render={data => (
      <div>
        <div className="mobile-logo-container">
          <div className="mobile-logo">
            <Img alt="Feleen Law Logo" fluid={data.logo.childImageSharp.fluid}/>
          </div>
          <div className="mobile-navbar_logo">
            <h1><Link to="/">Law Office of James G. Feleen</Link></h1>
            <h4>Serving West Central New Hampshire</h4>
          </div>
        </div>
        <Img alt="The upper valley in New England during fall" className="hero-large" fluid={data.hero.childImageSharp.fluid}/>
      </div>
    )}
  />
);

const query = graphql`
  query {
    hero: file(relativePath: { eq: "upper-valley.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "feleen-law-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 120, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default heroLarge;
